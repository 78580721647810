import React, { useState, useCallback, useRef } from 'react';
import { Container, Form, Button, Spinner, Alert, Card } from 'react-bootstrap';
import DashboardLayout from './DashboardLayout';
import SearchBar from './SearchBar';
import ScoreTable from './ScoreTable';
import { apiCall, fetchSuggestions, searchItem, submitWrestlerData } from '../utils/apiUtils';
import '../styles/scoreForm.css';

// Add this line at the top of your file
const apiUrl = process.env.NODE_ENV === 'production' 
  ? '' // Empty string for relative URL in production
  : process.env.REACT_APP_API_URL || 'http://localhost:5001';

function ScoreForm() {
  const [wrestlers, setWrestlers] = useState([]);
  const [selectedWrestler, setSelectedWrestler] = useState(null);
  const [selectedEvent, setSelectedEvent] = useState('');
  const [selectedMatch, setSelectedMatch] = useState(null);
  const [fullMatchDetails, setFullMatchDetails] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [youtubeUrl, setYoutubeUrl] = useState('');
  const youtubePlayerRef = useRef(null);

  const fetchWrestlerSuggestions = useCallback(async (query) => {
    return await fetchSuggestions('name', query);
  }, []);

  const searchWrestler = useCallback(async (query) => {
    setLoading(true);
    setError(null);
    try {
      const data = await searchItem('name', query);
      if (data.length > 0) {
        setSelectedWrestler(data[0]);
        setWrestlers(data);
        setSelectedEvent('');
        setSelectedMatch(null);
      } else {
        setError('No wrestler found with that name');
      }
    } catch (error) {
      setError(error.message);
    } finally {
      setLoading(false);
    }
  }, []);

  const handleEventSelect = (e) => {
    setSelectedEvent(e.target.value);
    setSelectedMatch(null);
  };

  const handleMatchSelect = (e) => {
    const selectedOption = selectedWrestler.matches.find(match => match.matchID === e.target.value);
    setSelectedMatch(selectedOption);
    setFullMatchDetails({
      ...selectedOption,
      scores: selectedOption.scores.map(score => ({
        ...score,
        setup: '',
        attack: '',
        finish: '',
        breakdown: '',
        ride: '',
        turn: '',
        defense: '',
        action: '',
        pinningCombo: ''
      }))
    });
    setYoutubeUrl(selectedOption.youtubeUrl || '');
  };

  const handleScoreDetailChange = (index, field, value) => {
    setFullMatchDetails(prevDetails => {
      const newScores = [...prevDetails.scores];
      newScores[index] = { ...newScores[index], [field]: value };
      return { ...prevDetails, scores: newScores };
    });
  };

  const handleYoutubeUrlChange = (e) => {
    setYoutubeUrl(e.target.value);
  };

  const handleScoreUpdate = (updatedScore) => {
    setFullMatchDetails(prevDetails => {
      const updatedScores = prevDetails.scores.map(score => 
        score._id === updatedScore._id ? updatedScore : score
      );
      return { ...prevDetails, scores: updatedScores };
    });
  };

  const extractVideoId = (url) => {
    const regExp = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|&v=)([^#&?]*).*/;
    const match = url.match(regExp);
    return (match && match[2].length === 11) ? match[2] : null;
  };

  const submitMatchDetails = async () => {
    setLoading(true);
    setError(null);
    try {
      const fullWrestlerData = {
        wrestlerId: selectedWrestler._id,
        name: selectedWrestler.name,
        team: selectedWrestler.team,
        youtubeUrl: youtubeUrl,
        matches: [{
          ...fullMatchDetails,
          matchID: fullMatchDetails.matchID,
          weightClass: fullMatchDetails.weightClass,
          scores: fullMatchDetails.scores.map(score => ({
            ...score,
            MatchPeriod: score.MatchPeriod,
            pointsScored: Number(score.pointsScored),
            periodNumber: Number(score.periodNumber),
            youtubeTimestamp: score.youtubeTimestamp || null
          })),
          round: fullMatchDetails.round,
          occurrenceValue: fullMatchDetails.occurrenceValue,
          matchUrl: fullMatchDetails.matchUrl
        }]
      };
      
      console.log('Submitting full wrestler data:', fullWrestlerData);

      const result = await apiCall(`${apiUrl}/api/fullWrestlers`, 'POST', fullWrestlerData);
      console.log('Saved full wrestler data:', result);
      alert('Match details submitted successfully!');
      setSelectedMatch(null);
      setFullMatchDetails(null);
      setYoutubeUrl('');
    } catch (error) {
      console.error('Error submitting match details:', error);
      setError(`Error submitting match details: ${error.message}`);
    } finally {
      setLoading(false);
    }
  };

  return (
    <DashboardLayout>
      <Container className="score-form-container">
        <h1 className="text-center mb-4">Wrestling Score Form</h1>
        
        {error && <Alert variant="danger">{error}</Alert>}

        <Card className="mb-4">
          <Card.Body>
            <Form.Group className="mb-4">
              <Form.Label className="form-label">Search Wrestler</Form.Label>
              <SearchBar onSearch={searchWrestler} fetchSuggestions={fetchWrestlerSuggestions} />
            </Form.Group>

            {loading && <Spinner animation="border" className="d-block mx-auto" />}

            {selectedWrestler && (
              <Form.Group className="mb-4">
                <Form.Label className="form-label">Select Event</Form.Label>
                <Form.Select value={selectedEvent} onChange={handleEventSelect} className="custom-select">
                  <option value="">Select an Event</option>
                  {[...new Set(selectedWrestler.matches.map(m => m.event))].map(event => (
                    <option key={event} value={event}>{event}</option>
                  ))}
                </Form.Select>
              </Form.Group>
            )}

            {selectedEvent && (
              <Form.Group className="mb-4">
                <Form.Label className="form-label">Select Match</Form.Label>
                <Form.Select onChange={handleMatchSelect} className="custom-select">
                  <option value="">Select a Match</option>
                  {selectedWrestler.matches
                    .filter(m => m.event === selectedEvent)
                    .map(match => (
                      <option key={match.matchID} value={match.matchID}>
                        {match.opponent} ({match.opponentTeam}) - {match.weightClass} lbs | 
                        Result: {match.matchResult} | Winner: {match.matchWinner}
                      </option>
                    ))}
                </Form.Select>
              </Form.Group>
            )}
          </Card.Body>
        </Card>

        {selectedMatch && fullMatchDetails && (
          <Card className="mt-4">
            <Card.Body>
              <h2>Match Details</h2>
              <Form.Group className="mb-3">
                <Form.Label>YouTube URL</Form.Label>
                <Form.Control
                  type="text"
                  value={youtubeUrl}
                  onChange={handleYoutubeUrlChange}
                  placeholder="Enter YouTube URL for this match"
                />
              </Form.Group>
              
              {youtubeUrl && (
                <div className="youtube-embed mb-3">
                  <iframe
                    width="100%"
                    height="315"
                    src={`https://www.youtube.com/embed/${extractVideoId(youtubeUrl)}`}
                    frameBorder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowFullScreen
                    ref={youtubePlayerRef}
                  ></iframe>
                </div>
              )}

              <ScoreTable 
                scores={fullMatchDetails.scores}
                matchResult={fullMatchDetails.matchResult}
                matchWinner={fullMatchDetails.matchWinner}
                youtubeUrl={youtubeUrl}
                youtubePlayerRef={youtubePlayerRef}
                onScoreUpdate={handleScoreUpdate}
              />
              <Button onClick={submitMatchDetails} className="mt-3">Submit Match Details</Button>
            </Card.Body>
          </Card>
        )}
      </Container>
    </DashboardLayout>
  );
}

export default ScoreForm;