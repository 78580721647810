import React, { useState, useCallback } from 'react';
import { Container, Row, Col, Card, Alert, Spinner } from 'react-bootstrap';
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer, PieChart, Pie, ComposedChart, Line, Area } from 'recharts';
import DashboardLayout from './DashboardLayout';
import DataVisualNavbar from './DataVisualNavbar';
import SearchBar from './SearchBar';
import { getAuth } from 'firebase/auth';
import '../styles/SharedDataVisual.css';
import { fetchSuggestions, searchItem } from '../utils/apiUtils';

const apiUrl = process.env.NODE_ENV === 'production' 
  ? '' // Empty string for relative URL in production
  : process.env.REACT_APP_API_URL || 'http://localhost:5001';

const EventDataVisual = () => {
  const [eventData, setEventData] = useState(null);
  const [wrestlerData, setWrestlerData] = useState(null); // Added state for wrestlerData
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const fetchWrestlerSuggestions = useCallback(async (query) => {
    return await fetchSuggestions('name', query);
  }, []);

  const searchWrestler = useCallback(async (query) => {
    setLoading(true);
    setError(null);
    try {
      const data = await searchItem('name', query);
      if (data.length > 0) {
        setWrestlerData(data[0]); // Now this line is valid
      } else {
        setError('No wrestler found with that name');
      }
    } catch (error) {
      setError(error.message);
    } finally {
      setLoading(false);
    }
  }, []);

  const fetchSuggestions = useCallback(async (query) => {
    if (query.length < 2) return [];
    try {
      const auth = getAuth();
      const user = auth.currentUser;
      
      if (!user) {
        throw new Error('User not authenticated');
      }

      const token = await user.getIdToken();
      
      const response = await fetch(`${apiUrl}/api/wrestlers?event=${query}`, {
        headers: {
          'Authorization': `Bearer ${token}`
        }
      });

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const data = await response.json();
      const events = [...new Set(data.flatMap(wrestler => 
        wrestler.matches.map(match => match.event)
      ))];
      return events;
    } catch (error) {
      console.error('Error fetching event suggestions:', error);
      return [];
    }
  }, []);

  const searchEvent = useCallback(async (query) => {
    setLoading(true);
    setError(null);
    try {
      const auth = getAuth();
      const user = auth.currentUser;
      
      if (!user) {
        throw new Error('User not authenticated');
      }

      const token = await user.getIdToken();
      
      const response = await fetch(`${apiUrl}/api/wrestlers?event=${query}`, {
        headers: {
          'Authorization': `Bearer ${token}`
        }
      });

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const data = await response.json();
      const eventMatches = data.flatMap(wrestler => 
        wrestler.matches.filter(match => match.event === query)
          .map(match => ({...match, wrestler: wrestler.name, team: wrestler.team}))
      );
      console.log('Event data received:', eventMatches);
      setEventData(eventMatches);
    } catch (error) {
      console.error('Error searching event:', error);
      setError(error.message);
    } finally {
      setLoading(false);
    }
  }, []);

  const prepareVisualizationData = () => {
    if (!eventData || eventData.length === 0) return null;

    const teamPerformance = eventData.reduce((acc, match) => {
      if (!acc[match.team]) {
        acc[match.team] = { wins: 0, losses: 0 };
      }
      if (match.matchWinner === match.wrestler) {
        acc[match.team].wins++;
      } else {
        acc[match.team].losses++;
      }
      return acc;
    }, {});

    const weightClassDistribution = eventData.reduce((acc, match) => {
      acc[match.weightClass] = (acc[match.weightClass] || 0) + 1;
      return acc;
    }, {});

    const matchResultTypes = eventData.reduce((acc, match) => {
      const resultType = match.matchResult.split(' ')[0];
      acc[resultType] = (acc[resultType] || 0) + 1;
      return acc;
    }, {});

    const pointsDistribution = eventData.reduce((acc, match) => {
      match.scores.forEach(score => {
        const period = score.periodNumber || 'Unknown';
        if (!acc[period]) {
          acc[period] = { period, points: 0 };
        }
        acc[period].points += score.pointsScored;
      });
      return Object.values(acc);
    }, {});

    const timelineData = eventData.map(match => ({
      time: match.TimeInPeriod,
      name: match.wrestler,
      points: match.scores.reduce((sum, score) => sum + score.pointsScored, 0),
    }));

    return {
      teamPerformance: Object.entries(teamPerformance).map(([team, record]) => ({
        name: team,
        wins: record.wins,
        losses: record.losses
      })),
      weightClassDistribution: Object.entries(weightClassDistribution).map(([weightClass, count]) => ({
        name: weightClass,
        value: count
      })),
      matchResultTypes: Object.entries(matchResultTypes).map(([type, count]) => ({
        name: type,
        value: count
      })),
      pointsDistribution,
      timelineData
    };
  };

  const visualizationData = prepareVisualizationData();

  return (
    <DashboardLayout>
      <Container className="data-visual-container">
        <h1 className="text-center mb-4">Event Data Visualization</h1>
        <DataVisualNavbar />
        
        <Row className="mb-4">
          <Col md={12}>
            <SearchBar onSearch={searchEvent} fetchSuggestions={fetchSuggestions} />
          </Col>
        </Row>

        {loading && <Spinner animation="border" />}
        {error && <Alert variant="danger">{error}</Alert>}

        {eventData && visualizationData && (
          <>
            <Row>
              <Col md={6} className="mb-4">
                <Card>
                  <Card.Body>
                    <Card.Title>Team Performance</Card.Title>
                    <ResponsiveContainer width="100%" height={300}>
                      <BarChart data={visualizationData.teamPerformance}>
                        <CartesianGrid strokeDasharray="3 3" />
                        <XAxis dataKey="name" />
                        <YAxis />
                        <Tooltip />
                        <Legend />
                        <Bar dataKey="wins" fill="#82ca9d" />
                        <Bar dataKey="losses" fill="#8884d8" />
                      </BarChart>
                    </ResponsiveContainer>
                  </Card.Body>
                </Card>
              </Col>
              <Col md={6} className="mb-4">
                <Card>
                  <Card.Body>
                    <Card.Title>Weight Class Distribution</Card.Title>
                    <ResponsiveContainer width="100%" height={300}>
                      <PieChart>
                        <Pie
                          data={visualizationData.weightClassDistribution}
                          dataKey="value"
                          nameKey="name"
                          cx="50%"
                          cy="50%"
                          outerRadius={80}
                          fill="#8884d8"
                          label
                        />
                        <Tooltip />
                        <Legend />
                      </PieChart>
                    </ResponsiveContainer>
                  </Card.Body>
                </Card>
              </Col>
            </Row>
            <Row>
              <Col md={6} className="mb-4">
                <Card>
                  <Card.Body>
                    <Card.Title>Match Result Types</Card.Title>
                    <ResponsiveContainer width="100%" height={300}>
                      <PieChart>
                        <Pie
                          data={visualizationData.matchResultTypes}
                          dataKey="value"
                          nameKey="name"
                          cx="50%"
                          cy="50%"
                          outerRadius={80}
                          fill="#82ca9d"
                          label
                        />
                        <Tooltip />
                        <Legend />
                      </PieChart>
                    </ResponsiveContainer>
                  </Card.Body>
                </Card>
              </Col>
              <Col md={6} className="mb-4">
                <Card>
                  <Card.Body>
                    <Card.Title>Points Distribution by Period</Card.Title>
                    <ResponsiveContainer width="100%" height={300}>
                      <BarChart data={visualizationData.pointsDistribution}>
                        <CartesianGrid strokeDasharray="3 3" />
                        <XAxis dataKey="period" />
                        <YAxis />
                        <Tooltip />
                        <Legend />
                        <Bar dataKey="points" fill="#ffc658" />
                      </BarChart>
                    </ResponsiveContainer>
                  </Card.Body>
                </Card>
              </Col>
            </Row>
            <Row>
              <Col md={12} className="mb-4">
                <Card>
                  <Card.Body>
                    <Card.Title>Match Progression Timeline</Card.Title>
                    <ResponsiveContainer width="100%" height={300}>
                      <ComposedChart data={visualizationData.timelineData}>
                        <CartesianGrid strokeDasharray="3 3" />
                        <XAxis dataKey="time" />
                        <YAxis />
                        <Tooltip />
                        <Legend />
                        <Line type="monotone" dataKey="points" stroke="#ff7300" />
                        <Area type="monotone" dataKey="points" fill="#ff7300" stroke="#ff7300" />
                      </ComposedChart>
                    </ResponsiveContainer>
                  </Card.Body>
                </Card>
              </Col>
            </Row>
          </>
        )}
      </Container>
    </DashboardLayout>
  );
};

export default EventDataVisual;