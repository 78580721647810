import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { useAuthState } from 'react-firebase-hooks/auth';
import { auth } from '../utils/firebase';
import styled from 'styled-components';
import { FaClipboard, FaChartBar, FaRobot } from 'react-icons/fa';
import DashboardLayout from './DashboardLayout';

const StyledContainer = styled(Container)`
  color: #ffffff;
  padding: 2rem 0;
`;

const WelcomeText = styled.h1`
  font-size: 3.5rem;
  font-weight: 300;
  margin-bottom: 0.5rem;
`;

const SubText = styled.h2`
  font-size: 2rem;
  color: #888;
  font-weight: 300;
  margin-bottom: 3rem;
`;

const OrangeText = styled.span`
  color: #cc5500;
`;

const OptionContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  padding: 2rem;
  border: 1px solid #333;
  border-radius: 8px;
  transition: all 0.3s ease;

  &:hover {
    background-color: #1a1a1a;
    transform: translateY(-5px);
  }
`;

const IconWrapper = styled.div`
  font-size: 4rem;
  color: #cc5500;
  margin-bottom: 1rem;
`;

const OptionTitle = styled(Link)`
  color: #0066cc;
  font-size: 1.5rem;
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }
`;

const Home = () => {
  const [user] = useAuthState(auth);

  const displayName = user ? (user.email || 'Wrestler') : 'Wrestler';

  const options = [
    { title: 'Score Matches', icon: FaClipboard, link: '/scoreform' },
    { title: 'Visual Data', icon: FaChartBar, link: '/data-visual' },
    { title: 'Ask AI', icon: FaRobot, link: '/aicoach' },
  ];

  return (
    <DashboardLayout>
      <StyledContainer>
        <WelcomeText>
          Welcome, <OrangeText>{displayName}</OrangeText>
        </WelcomeText>
        <SubText>What would you like to do today?</SubText>
        <Row>
          {options.map((option, index) => (
            <Col md={4} key={index} className="mb-4">
              <OptionContainer>
                <IconWrapper>
                  <option.icon />
                </IconWrapper>
                <OptionTitle to={option.link}>{option.title}</OptionTitle>
              </OptionContainer>
            </Col>
          ))}
        </Row>
      </StyledContainer>
    </DashboardLayout>
  );
};

export default Home;