// src/components/SearchBar.js
import React, { useState, useEffect, useRef, useCallback } from 'react';
import { Form, ListGroup } from 'react-bootstrap';
import '../styles/SharedDataVisual.css';

// Use the environment variable for the API URL
const apiUrl = process.env.NODE_ENV === 'production' 
  ? '' // Empty string for relative URL in production
  : process.env.REACT_APP_API_URL || 'http://localhost:5001';

const SearchBar = ({ onSearch, fetchSuggestions }) => {
  const [query, setQuery] = useState('');
  const [suggestions, setSuggestions] = useState([]);
  const [showSuggestions, setShowSuggestions] = useState(false);
  const [error, setError] = useState(null);
  const searchRef = useRef(null);

  const getSuggestions = useCallback(async () => {
    if (query.length > 1) {
      try {
        console.log('Fetching suggestions for query:', query);
        console.log('API URL:', `${apiUrl}/api/wrestlers?name=${query}`);
        const fetchedSuggestions = await fetchSuggestions(query);
        console.log('Received suggestions:', fetchedSuggestions);
        setSuggestions(fetchedSuggestions);
        setShowSuggestions(true);
        setError(null);
      } catch (error) {
        console.error('Error fetching suggestions:', error);
        setError('Failed to fetch suggestions. Please try again.');
        setSuggestions([]);
        setShowSuggestions(false);
      }
    } else {
      setSuggestions([]);
      setShowSuggestions(false);
      setError(null);
    }
  }, [query, fetchSuggestions]);

  useEffect(() => {
    const timeoutId = setTimeout(getSuggestions, 300);
    return () => clearTimeout(timeoutId);
  }, [getSuggestions]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (searchRef.current && !searchRef.current.contains(event.target)) {
        setShowSuggestions(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const handleSearch = (searchQuery) => {
    setQuery(searchQuery);
    onSearch(searchQuery);
    setShowSuggestions(false);
  };

  return (
    <div ref={searchRef} className="search-bar-container">
      <Form.Control
        type="text"
        placeholder="Search wrestler..."
        value={query}
        onChange={(e) => setQuery(e.target.value)}
        className="search-input"
      />
      {error && <div className="error-message">{error}</div>}
      {showSuggestions && suggestions.length > 0 && (
        <ListGroup className="search-suggestions">
          {suggestions.map((suggestion, index) => (
            <ListGroup.Item
              key={index}
              action
              onClick={() => handleSearch(suggestion)}
            >
              {suggestion}
            </ListGroup.Item>
          ))}
        </ListGroup>
      )}
    </div>
  );
};

export default SearchBar;