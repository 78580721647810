import React, { useState, useCallback, useMemo } from 'react';
import { Container, Row, Col, Card, Alert, Spinner, Table, Form, Button } from 'react-bootstrap';
import { ResponsiveContainer, RadarChart, PolarGrid, PolarAngleAxis, PolarRadiusAxis, Radar, Tooltip, Legend, Sankey, LineChart, Line, XAxis, YAxis, CartesianGrid, PieChart, Pie, Cell } from 'recharts';
import DashboardLayout from './DashboardLayout';
import DataVisualNavbar from './DataVisualNavbar';
import SearchBar from './SearchBar';
import { getAuth } from 'firebase/auth';
import '../styles/SharedDataVisual.css';
import ScoutingReport from './ScoutingReport';
import html2canvas from 'html2canvas';
import { fetchSuggestions, searchItem } from '../utils/apiUtils'; // Import new API functions

// New component for Sunburst Chart
const SunburstChart = ({ data }) => {
  const COLORS = ['#0088FE', '#00C49F', '#FFBB28', '#FF8042', '#8884D8'];

  return (
    <ResponsiveContainer width="100%" height={300}>
      <PieChart>
        <Pie
          data={data}
          dataKey="value"
          nameKey="name"
          cx="50%"
          cy="50%"
          outerRadius={80}
          fill="#8884d8"
          label
        >
          {data.map((entry, index) => (
            <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
          ))}
        </Pie>
        <Tooltip />
        <Legend />
      </PieChart>
    </ResponsiveContainer>
  );
};

// Add this line at the top of your file
const apiUrl = process.env.NODE_ENV === 'production' 
  ? '' // Empty string for relative URL in production
  : process.env.REACT_APP_API_URL || 'http://localhost:5001';

const WrestlerDataVisual = () => {
  const [wrestlerData, setWrestlerData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [selectedMetric, setSelectedMetric] = useState('points');
  const [showScoutingReport, setShowScoutingReport] = useState(false);

  // Update fetchSuggestions function
  const fetchWrestlerSuggestions = useCallback(async (query) => {
    return await fetchSuggestions('name', query);
  }, []);

  // Update searchWrestler function
  const searchWrestler = useCallback(async (query) => {
    setLoading(true);
    setError(null);
    try {
      const data = await searchItem('name', query); // Use searchItem instead of fetch
      if (data.length > 0) {
        setWrestlerData(data[0]);
      } else {
        setError('No wrestler found with that name');
      }
    } catch (error) {
      setError(error.message);
    } finally {
      setLoading(false);
    }
  }, []);

  const prepareVisualizationData = useMemo(() => {
    if (!wrestlerData) return null;

    // Process skills data for RadarChart
    const skillsData = wrestlerData.matches.reduce((acc, match) => {
      match.scores.forEach(score => {
        if (score.scoringWrestler === wrestlerData.name) {
          acc[score.pointType] = (acc[score.pointType] || 0) + score.pointsScored;
        }
      });
      return acc;
    }, {});

    const radarData = Object.entries(skillsData).map(([skill, value]) => ({
      skill,
      value
    }));

    // Process data for SankeyChart
    const nodes = [];
    const nodeMap = {};
    const links = [];

    wrestlerData.matches.forEach(match => {
      const source = match.round;
      const target = match.matchWinner === wrestlerData.name ? 'Win' : 'Loss';

      if (!nodeMap[source]) {
        nodeMap[source] = nodes.length;
        nodes.push({ name: source });
      }

      if (!nodeMap[target]) {
        nodeMap[target] = nodes.length;
        nodes.push({ name: target });
      }

      links.push({ source: nodeMap[source], target: nodeMap[target], value: 1 });
    });

    const sankeyData = { nodes, links };

    // Process performance over time for LineChart
    const performanceOverTime = wrestlerData.matches.map((match, index) => ({
      match: index + 1,
      points: match.scores.reduce((sum, score) => 
        sum + (score.scoringWrestler === wrestlerData.name ? score.pointsScored : 0), 0),
      result: match.matchWinner === wrestlerData.name ? 'Win' : 'Loss'
    }));

    // Process match outcomes for Sunburst Chart
    const matchOutcomes = wrestlerData.matches.reduce((acc, match) => {
      const resultType = match.matchResult.split(' ')[0]; // e.g., "Dec", "MD", "Fall"
      acc[resultType] = (acc[resultType] || 0) + 1;
      return acc;
    }, {});

    const sunburstData = Object.entries(matchOutcomes).map(([name, value]) => ({
      name,
      value
    }));

    return { radarData, sankeyData, performanceOverTime, sunburstData };
  }, [wrestlerData]);

  const handleMetricChange = (event) => {
    setSelectedMetric(event.target.value);
  };

  const generateScoutingReport = useCallback(() => {
    setShowScoutingReport(true);
    setTimeout(() => {
      const reportElement = document.getElementById('scouting-report');
      html2canvas(reportElement).then((canvas) => {
        const link = document.createElement('a');
        link.download = `${wrestlerData.name}_scouting_report.png`;
        link.href = canvas.toDataURL();
        link.click();
        setShowScoutingReport(false);
      });
    }, 1000);
  }, [wrestlerData]);

  return (
    <DashboardLayout>
      <Container fluid className="data-visual-container">
        <h1 className="text-center mb-4">Wrestler Data Visualization</h1>
        <DataVisualNavbar />
        
        <Row className="mb-4">
          <Col md={12}>
            <SearchBar onSearch={searchWrestler} fetchSuggestions={fetchWrestlerSuggestions} />
          </Col>
        </Row>

        {loading && <Spinner animation="border" />}
        {error && <Alert variant="danger">{error}</Alert>}

        {wrestlerData && prepareVisualizationData && (
          <>
            <Row className="mb-4">
              <Col md={12}>
                <Button variant="primary" onClick={generateScoutingReport}>
                  Generate Scouting Report
                </Button>
              </Col>
            </Row>

            <Row>
              <Col md={6} className="mb-4">
                <Card>
                  <Card.Body>
                    <Card.Title>Wrestler Skills Radar</Card.Title>
                    <ResponsiveContainer width="100%" height={300}>
                      <RadarChart data={prepareVisualizationData.radarData}>
                        <PolarGrid />
                        <PolarAngleAxis dataKey="skill" />
                        <PolarRadiusAxis angle={30} domain={[0, 'auto']} />
                        <Radar name={wrestlerData.name} dataKey="value" stroke="#8884d8" fill="#8884d8" fillOpacity={0.6} />
                        <Tooltip />
                        <Legend />
                      </RadarChart>
                    </ResponsiveContainer>
                  </Card.Body>
                </Card>
              </Col>
              <Col md={6} className="mb-4">
                <Card>
                  <Card.Body>
                    <Card.Title>Tournament Progress</Card.Title>
                    <ResponsiveContainer width="100%" height={300}>
                      <Sankey
                        data={prepareVisualizationData.sankeyData}
                        node={{ padding: 15, thickness: 20 }}
                        link={{ stroke: '#77c878' }}
                      >
                        <Tooltip />
                      </Sankey>
                    </ResponsiveContainer>
                  </Card.Body>
                </Card>
              </Col>
            </Row>
            <Row>
              <Col md={6} className="mb-4">
                <Card>
                  <Card.Body>
                    <Card.Title>Performance Over Time</Card.Title>
                    <Form.Group className="mb-3">
                      <Form.Label>Select Metric</Form.Label>
                      <Form.Select value={selectedMetric} onChange={handleMetricChange}>
                        <option value="points">Points Scored</option>
                        <option value="result">Match Result</option>
                      </Form.Select>
                    </Form.Group>
                    <ResponsiveContainer width="100%" height={300}>
                      <LineChart data={prepareVisualizationData.performanceOverTime}>
                        <CartesianGrid strokeDasharray="3 3" />
                        <XAxis dataKey="match" />
                        <YAxis />
                        <Tooltip />
                        <Legend />
                        <Line type="monotone" dataKey={selectedMetric} stroke="#8884d8" />
                      </LineChart>
                    </ResponsiveContainer>
                  </Card.Body>
                </Card>
              </Col>
              <Col md={6} className="mb-4">
                <Card>
                  <Card.Body>
                    <Card.Title>Match Outcomes</Card.Title>
                    <SunburstChart data={prepareVisualizationData.sunburstData} />
                  </Card.Body>
                </Card>
              </Col>
            </Row>
            <Row>
              <Col md={12} className="mb-4">
                <Card>
                  <Card.Body>
                    <Card.Title>Match History</Card.Title>
                    <Table striped bordered hover responsive>
                      <thead>
                        <tr>
                          <th>Event</th>
                          <th>Round</th>
                          <th>Opponent</th>
                          <th>Result</th>
                          <th>Points Scored</th>
                          <th>Points Allowed</th>
                        </tr>
                      </thead>
                      <tbody>
                        {wrestlerData.matches.map((match, index) => (
                          <tr key={index}>
                            <td>{match.event}</td>
                            <td>{match.round}</td>
                            <td>{match.opponent} ({match.opponentTeam})</td>
                            <td>{match.matchResult}</td>
                            <td>
                              {match.scores.reduce((sum, score) => 
                                sum + (score.scoringWrestler === wrestlerData.name ? score.pointsScored : 0), 0
                              )}
                            </td>
                            <td>
                              {match.scores.reduce((sum, score) => 
                                sum + (score.scoringWrestler !== wrestlerData.name ? score.pointsScored : 0), 0
                              )}
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </Table>
                  </Card.Body>
                </Card>
              </Col>
            </Row>

            {showScoutingReport && (
              <div id="scouting-report" style={{ position: 'absolute', left: '-9999px' }}>
                <ScoutingReport wrestlerData={wrestlerData} visualizationData={prepareVisualizationData} />
              </div>
            )}
          </>
        )}
      </Container>
    </DashboardLayout>
  );
};

export default WrestlerDataVisual;