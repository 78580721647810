import React from 'react';
import { Container, Card, Row, Col } from 'react-bootstrap';

function AiCoach() {
  return (
    <Container className="text-center my-5">
      <h1>AI Coach</h1>
      <p>Get personalized coaching tips and strategies powered by AI.</p>

      <Row className="text-center my-5">
        <Col md={{ span: 6, offset: 3 }}>
          <Card bg="dark" text="white">
            <Card.Body>
              <Card.Title>Coming Soon</Card.Title>
              <Card.Text>
                We are working hard to bring you the best AI-powered coaching tips. Stay tuned!
              </Card.Text>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Container>
  );
}

export default AiCoach;
