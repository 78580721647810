import React from 'react';
import { Card, Row, Col } from 'react-bootstrap';
import { 
  PieChart, 
  Pie, 
  Cell, 
  BarChart, 
  Bar, 
  XAxis, 
  YAxis, 
  Tooltip, 
  Legend, 
  ResponsiveContainer,
  CartesianGrid,
  RadarChart,
  PolarGrid,
  PolarAngleAxis,
  PolarRadiusAxis,
  Radar
} from 'recharts';

const COLORS = ['#0088FE', '#00C49F', '#FFBB28', '#FF8042', '#8884D8'];

const ScoutingReport = ({ wrestlerData, visualizationData }) => {
  // Calculate win/loss ratio
  const totalMatches = wrestlerData.matches.length;
  const wins = wrestlerData.matches.filter(match => match.matchWinner === wrestlerData.name).length;
  const losses = totalMatches - wins;
  const winLossRatio = (wins / totalMatches * 100).toFixed(2);

  // Prepare data for points scored by period
  const pointsByPeriod = wrestlerData.matches.reduce((acc, match) => {
    match.scores.forEach(score => {
      if (score.scoringWrestler === wrestlerData.name) {
        const period = score.MatchPeriod.includes('Period') ? score.MatchPeriod : 'Other';
        acc[period] = (acc[period] || 0) + score.pointsScored;
      }
    });
    return acc;
  }, {});

  const pointsByPeriodData = Object.entries(pointsByPeriod).map(([period, points]) => ({
    period,
    points
  }));

  return (
    <Card className="scouting-report">
      <Card.Body>
        <h2 className="text-center mb-4">{wrestlerData.name} - Scouting Report</h2>
        <Row>
          <Col md={6}>
            <p><strong>Team:</strong> {wrestlerData.team}</p>
            <p><strong>Weight Class:</strong> {wrestlerData.matches[0]?.weightClass} lbs</p>
            <p><strong>Win/Loss Ratio:</strong> {winLossRatio}% ({wins}W - {losses}L)</p>
          </Col>
          <Col md={6}>
            <h4>Match Outcomes</h4>
            <ResponsiveContainer width="100%" height={200}>
              <PieChart>
                <Pie
                  data={visualizationData.sunburstData}
                  dataKey="value"
                  nameKey="name"
                  cx="50%"
                  cy="50%"
                  outerRadius={80}
                  fill="#8884d8"
                  label
                >
                  {visualizationData.sunburstData.map((entry, index) => (
                    <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                  ))}
                </Pie>
                <Tooltip />
                <Legend />
              </PieChart>
            </ResponsiveContainer>
          </Col>
        </Row>
        <Row className="mt-4">
          <Col md={6}>
            <h4>Points Scored by Period</h4>
            <ResponsiveContainer width="100%" height={200}>
              <BarChart data={pointsByPeriodData}>
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="period" />
                <YAxis />
                <Tooltip />
                <Legend />
                <Bar dataKey="points" fill="#8884d8" />
              </BarChart>
            </ResponsiveContainer>
          </Col>
          <Col md={6}>
            <h4>Wrestler Skills</h4>
            <ResponsiveContainer width="100%" height={200}>
              <RadarChart data={visualizationData.radarData}>
                <PolarGrid />
                <PolarAngleAxis dataKey="skill" />
                <PolarRadiusAxis angle={30} domain={[0, 'auto']} />
                <Radar name={wrestlerData.name} dataKey="value" stroke="#8884d8" fill="#8884d8" fillOpacity={0.6} />
                <Tooltip />
              </RadarChart>
            </ResponsiveContainer>
          </Col>
        </Row>
        <Row className="mt-4">
          <Col md={12}>
            <h4>Recent Match History</h4>
            <table className="table table-striped">
              <thead>
                <tr>
                  <th>Event</th>
                  <th>Opponent</th>
                  <th>Result</th>
                </tr>
              </thead>
              <tbody>
                {wrestlerData.matches.slice(0, 5).map((match, index) => (
                  <tr key={index}>
                    <td>{match.event}</td>
                    <td>{match.opponent} ({match.opponentTeam})</td>
                    <td>{match.matchResult}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </Col>
        </Row>
      </Card.Body>
    </Card>
  );
};

export default ScoutingReport;