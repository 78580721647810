import React, { useState, useMemo } from 'react';
import { Table, Button, Form } from 'react-bootstrap';

const ScoreTable = ({ scores, matchResult, matchWinner, youtubeUrl, youtubePlayerRef, onScoreUpdate }) => {
  const [currentScoreIndex, setCurrentScoreIndex] = useState(0);

  const scoringActions = useMemo(() => {
    return scores.filter(score => 
      score.pointType.toLowerCase().includes('escape') ||
      score.pointType.toLowerCase().includes('takedown') ||
      score.pointType.toLowerCase().includes('reversal') ||
      score.pointType.toLowerCase().includes('near fall') ||
      score.pointType.includes('+1') ||
      score.pointType.includes('+3') ||
      score.pointType.includes('+4')
    );
  }, [scores]);

  const handlePrevScore = () => {
    setCurrentScoreIndex(Math.max(0, currentScoreIndex - 1));
  };

  const handleNextScore = () => {
    setCurrentScoreIndex(Math.min(scoringActions.length - 1, currentScoreIndex + 1));
  };

  const handleClip = () => {
    if (youtubePlayerRef.current && youtubePlayerRef.current.getCurrentTime) {
      const currentTime = youtubePlayerRef.current.getCurrentTime();
      const adjustedTime = Math.max(0, currentTime - 5); // 5 seconds earlier, but not before 0
      const timestamp = formatTimestamp(adjustedTime);
      
      const updatedScore = {
        ...scoringActions[currentScoreIndex],
        youtubeTimestamp: timestamp
      };
      
      onScoreUpdate(updatedScore);
    }
  };

  const formatTimestamp = (seconds) => {
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = Math.floor(seconds % 60);
    return `${minutes}:${remainingSeconds.toString().padStart(2, '0')}`;
  };

  const currentScore = scoringActions[currentScoreIndex];

  if (scoringActions.length === 0) {
    return <p>No scoring actions in this match.</p>;
  }

  return (
    <div className="score-table-container" style={{ maxHeight: '500px', overflowY: 'auto' }}>
      <Table striped bordered hover>
        <thead>
          <tr>
            <th>Period</th>
            <th>Wrestler</th>
            <th>Points</th>
            <th>Time</th>
            <th>Action</th>
            <th>Details</th>
            <th>Clip</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>{currentScore.MatchPeriod}</td>
            <td>{currentScore.scoringWrestler}</td>
            <td>{currentScore.pointsScored}</td>
            <td>{currentScore.TimeInPeriod}</td>
            <td>{currentScore.pointType}</td>
            <td className="details-cell">
              {renderScoreDetails(currentScore, onScoreUpdate)}
            </td>
            <td>
              <Button onClick={handleClip} size="sm">Clip</Button>
              {currentScore.youtubeTimestamp && <p>Clipped at: {currentScore.youtubeTimestamp}</p>}
            </td>
          </tr>
        </tbody>
      </Table>
      <div className="d-flex justify-content-between align-items-center mt-2">
        <Button onClick={handlePrevScore} disabled={currentScoreIndex === 0}>Previous</Button>
        <span>{`Score ${currentScoreIndex + 1} of ${scoringActions.length}`}</span>
        <Button onClick={handleNextScore} disabled={currentScoreIndex === scoringActions.length - 1}>Next</Button>
      </div>
      <div className="mt-3">
        <h5>Match Result</h5>
        <p>{matchResult} - Winner: {matchWinner}</p>
      </div>
    </div>
  );
};

const renderScoreDetails = (score, onScoreUpdate) => {
  const handleDetailChange = (field, value) => {
    const updatedScore = {
      ...score,
      [field]: value
    };
    onScoreUpdate(updatedScore);
  };

  if (score.pointType.toLowerCase().includes('takedown')) {
    return (
      <>
        <Form.Group className="mb-2">
          <Form.Label>Setup</Form.Label>
          <Form.Select value={score.setup || ''} onChange={(e) => handleDetailChange('setup', e.target.value)}>
            <option value="">Select Setup</option>
            <option value="Underhook">Underhook</option>
            <option value="Double Underhook">Double Underhook</option>
            <option value="Over-under">Over-under</option>
            {/* Add more options as needed */}
          </Form.Select>
        </Form.Group>
        <Form.Group className="mb-2">
          <Form.Label>Attack</Form.Label>
          <Form.Select value={score.attack || ''} onChange={(e) => handleDetailChange('attack', e.target.value)}>
            <option value="">Select Attack</option>
            <option value="Double">Double</option>
            <option value="Single">Single</option>
            <option value="High Crotch">High Crotch</option>
            {/* Add more options as needed */}
          </Form.Select>
        </Form.Group>
        <Form.Group className="mb-2">
          <Form.Label>Finish</Form.Label>
          <Form.Select value={score.finish || ''} onChange={(e) => handleDetailChange('finish', e.target.value)}>
            <option value="">Select Finish</option>
            <option value="Run the Pipe">Run the Pipe</option>
            <option value="Lift">Lift</option>
            <option value="Trip">Trip</option>
            {/* Add more options as needed */}
          </Form.Select>
        </Form.Group>
      </>
    );
  } else if (score.pointType.toLowerCase().includes('escape') || score.pointType.toLowerCase().includes('reversal')) {
    return (
      <>
        <Form.Group className="mb-2">
          <Form.Label>Defense</Form.Label>
          <Form.Select value={score.defense || ''} onChange={(e) => handleDetailChange('defense', e.target.value)}>
            <option value="">Select Defense</option>
            <option value="Stand Up">Stand Up</option>
            <option value="Sit Out">Sit Out</option>
            <option value="Switch">Switch</option>
            {/* Add more options as needed */}
          </Form.Select>
        </Form.Group>
        <Form.Group className="mb-2">
          <Form.Label>Action</Form.Label>
          <Form.Select value={score.action || ''} onChange={(e) => handleDetailChange('action', e.target.value)}>
            <option value="">Select Action</option>
            <option value="Escape">Escape</option>
            <option value="Reversal">Reversal</option>
          </Form.Select>
        </Form.Group>
      </>
    );
  } else if (score.pointType.toLowerCase().includes('near fall')) {
    return (
      <>
        <Form.Group className="mb-2">
          <Form.Label>Turn</Form.Label>
          <Form.Select value={score.turn || ''} onChange={(e) => handleDetailChange('turn', e.target.value)}>
            <option value="">Select Turn</option>
            <option value="Half Nelson">Half Nelson</option>
            <option value="Arm Bar">Arm Bar</option>
            <option value="Cradle">Cradle</option>
            {/* Add more options as needed */}
          </Form.Select>
        </Form.Group>
      </>
    );
  } else {
    return <p>No additional details for this action.</p>;
  }
};

const convertTimeStampToSeconds = (timeStamp) => {
  if (!timeStamp) return 0;
  const [minutes, seconds] = timeStamp.split(':').map(Number);
  return minutes * 60 + seconds;
};

export default ScoreTable;