import React, { useState, useCallback, useMemo } from 'react';
import { Container, Row, Col, Card, Alert, Spinner, Table, Form } from 'react-bootstrap';
import { ResponsiveContainer, BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend, PieChart, Pie, Cell, LineChart, Line, AreaChart, Area } from 'recharts';
import DashboardLayout from './DashboardLayout';
import DataVisualNavbar from './DataVisualNavbar';
import SearchBar from './SearchBar';
import { getAuth } from 'firebase/auth';
import '../styles/SharedDataVisual.css';
import { fetchSuggestions, searchItem } from '../utils/apiUtils'; // Added import

// Custom color palette
const COLORS = {
  primary: '#003f5c',
  secondary: '#58508d',
  tertiary: '#bc5090',
  quaternary: '#ff6361',
  quinary: '#ffa600',
};

// Helper function to convert time string to seconds
const convertTimeToSeconds = (timeString) => {
  const [minutes, seconds] = timeString.split(':').map(Number);
  return minutes * 60 + seconds;
};

// Helper function to categorize time into intervals
const categorizeTime = (seconds) => {
  if (seconds <= 60) return '0-1 min';
  if (seconds <= 120) return '1-2 min';
  return '2-3 min';
};

// Add this line at the top of your file
const apiUrl = process.env.NODE_ENV === 'production' 
  ? '' // Empty string for relative URL in production
  : process.env.REACT_APP_API_URL || 'http://localhost:5001';

const TeamDataVisual = () => {
  const [teamData, setTeamData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [wrestlerData, setWrestlerData] = useState(null); // Added state for wrestler data

  const fetchWrestlerSuggestions = useCallback(async (query) => { // New function
    if (!query) return []; // Added check for empty query
    return await fetchSuggestions('name', query);
  }, []);

  const searchWrestler = useCallback(async (query) => { // New function
    if (!query) return; // Added check for empty query
    setLoading(true);
    setError(null);
    try {
      const data = await searchItem('name', query);
      if (data.length > 0) {
        setWrestlerData(data[0]); // Assuming setWrestlerData is defined
      } else {
        setError('No wrestler found with that name');
      }
    } catch (error) {
      setError(error.message);
    } finally {
      setLoading(false);
    }
  }, []);

  const fetchSuggestions = useCallback(async (query) => {
    if (!query || query.length < 2) return []; // Added check for empty query
    try {
      const auth = getAuth();
      const user = auth.currentUser;
      
      if (!user) {
        throw new Error('User not authenticated');
      }

      const token = await user.getIdToken();
      
      const response = await fetch(`${apiUrl}/api/wrestlers?team=${query}`, {
        headers: {
          'Authorization': `Bearer ${token}`
        }
      });

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const data = await response.json();
      const teams = [...new Set(data.map(wrestler => wrestler.team))];
      return teams;
    } catch (error) {
      console.error('Error fetching team suggestions:', error);
      return [];
    }
  }, []);

  const searchTeam = useCallback(async (query) => {
    setLoading(true);
    setError(null);
    try {
      const auth = getAuth();
      const user = auth.currentUser;
      
      if (!user) {
        throw new Error('User not authenticated');
      }

      const token = await user.getIdToken();
      
      const response = await fetch(`${apiUrl}/api/wrestlers?team=${query}`, {
        headers: {
          'Authorization': `Bearer ${token}`
        }
      });

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const data = await response.json();
      const teamData = data.filter(wrestler => wrestler.team === query);
      console.log('Team data received:', teamData);
      setTeamData(teamData); // Changed from setWrestlerData to setTeamData
    } catch (error) {
      console.error('Error searching team:', error);
      setError(error.message);
    } finally {
      setLoading(false);
    }
  }, []);

  const prepareVisualizationData = useMemo(() => {
    if (!teamData || teamData.length === 0) return null;

    const teamName = teamData[0].team;

    // Win/Loss Data
    const winLossData = teamData.reduce((acc, wrestler) => {
      wrestler.matches.forEach(match => {
        if (match.matchWinner === wrestler.name) {
          acc.wins++;
        } else {
          acc.losses++;
        }
      });
      return acc;
    }, { wins: 0, losses: 0 });

    // Wrestler Performance Data
    const wrestlerPerformanceData = teamData.map(wrestler => ({
      name: wrestler.name,
      wins: wrestler.matches.filter(match => match.matchWinner === wrestler.name).length,
      losses: wrestler.matches.filter(match => match.matchWinner !== wrestler.name).length
    }));

    // Weight Class Distribution
    const weightClassDistribution = teamData.reduce((acc, wrestler) => {
      const weightClass = wrestler.matches[0]?.weightClass;
      if (weightClass) {
        acc[weightClass] = (acc[weightClass] || 0) + 1;
      }
      return acc;
    }, {});

    // Matches Lost After Having a Lead
    const matchesLostAfterLead = teamData.reduce((acc, wrestler) => {
      wrestler.matches.forEach(match => {
        if (match.matchWinner !== wrestler.name) {
          let hadLead = false;
          let currentScore = 0;
          for (const score of match.scores) {
            if (score.scoringWrestler === wrestler.name) {
              currentScore += score.pointsScored;
            } else {
              currentScore -= score.pointsScored;
            }
            if (currentScore > 0) {
              hadLead = true;
              break;
            }
          }
          if (hadLead) acc++;
        }
      });
      return acc;
    }, 0);

    // Points Scored by Period
    const pointsByPeriod = teamData.reduce((acc, wrestler) => {
      wrestler.matches.forEach(match => {
        match.scores.forEach(score => {
          if (score.scoringWrestler === wrestler.name) {
            acc[score.MatchPeriod] = (acc[score.MatchPeriod] || 0) + score.pointsScored;
          }
        });
      });
      return acc;
    }, {});

    // Points Scored by Time in Period
    const pointsByTime = teamData.reduce((acc, wrestler) => {
      wrestler.matches.forEach(match => {
        match.scores.forEach(score => {
          if (score.scoringWrestler === wrestler.name) {
            const timeInSeconds = convertTimeToSeconds(score.TimeInPeriod);
            const timeCategory = categorizeTime(timeInSeconds);
            acc[timeCategory] = (acc[timeCategory] || 0) + score.pointsScored;
          }
        });
      });
      return acc;
    }, {});

    return {
      teamName,
      winLossData: [
        { name: 'Wins', value: winLossData.wins },
        { name: 'Losses', value: winLossData.losses }
      ],
      wrestlerPerformanceData,
      weightClassDistribution: Object.entries(weightClassDistribution).map(([weightClass, count]) => ({
        name: weightClass,
        value: count
      })),
      matchesLostAfterLead,
      pointsByPeriod: Object.entries(pointsByPeriod).map(([period, points]) => ({
        name: period,
        points
      })),
      pointsByTime: Object.entries(pointsByTime).map(([time, points]) => ({
        name: time,
        points
      }))
    };
  }, [teamData]);

  return (
    <DashboardLayout>
      <Container fluid className="data-visual-container">
        <h1 className="text-center mb-4">Team Data Visualization</h1>
        <DataVisualNavbar />
        
        <Row className="mb-4">
          <Col md={12}>
            <SearchBar onSearch={searchTeam} fetchSuggestions={fetchSuggestions} />
          </Col>
        </Row>

        {loading && <Spinner animation="border" />}
        {error && <Alert variant="danger">{error}</Alert>}

        {teamData && prepareVisualizationData && (
          <>
            <h2 className="text-center mb-4">{prepareVisualizationData.teamName}</h2>
            <Row>
              <Col md={6} className="mb-4">
                <Card>
                  <Card.Body>
                    <Card.Title>Team Win/Loss Ratio</Card.Title>
                    <ResponsiveContainer width="100%" height={300}>
                      <PieChart>
                        <Pie
                          data={prepareVisualizationData.winLossData}
                          dataKey="value"
                          nameKey="name"
                          cx="50%"
                          cy="50%"
                          outerRadius={80}
                          label
                        >
                          {prepareVisualizationData.winLossData.map((entry, index) => (
                            <Cell key={`cell-${index}`} fill={index === 0 ? COLORS.primary : COLORS.secondary} />
                          ))}
                        </Pie>
                        <Tooltip />
                        <Legend />
                      </PieChart>
                    </ResponsiveContainer>
                  </Card.Body>
                </Card>
              </Col>
              <Col md={6} className="mb-4">
                <Card>
                  <Card.Body>
                    <Card.Title>Wrestler Performance</Card.Title>
                    <ResponsiveContainer width="100%" height={300}>
                      <BarChart data={prepareVisualizationData.wrestlerPerformanceData}>
                        <CartesianGrid strokeDasharray="3 3" />
                        <XAxis dataKey="name" />
                        <YAxis />
                        <Tooltip />
                        <Legend />
                        <Bar dataKey="wins" fill={COLORS.primary} />
                        <Bar dataKey="losses" fill={COLORS.secondary} />
                      </BarChart>
                    </ResponsiveContainer>
                  </Card.Body>
                </Card>
              </Col>
            </Row>
            <Row>
              <Col md={6} className="mb-4">
                <Card>
                  <Card.Body>
                    <Card.Title>Weight Class Distribution</Card.Title>
                    <ResponsiveContainer width="100%" height={300}>
                      <PieChart>
                        <Pie
                          data={prepareVisualizationData.weightClassDistribution}
                          dataKey="value"
                          nameKey="name"
                          cx="50%"
                          cy="50%"
                          outerRadius={80}
                          label
                        >
                          {prepareVisualizationData.weightClassDistribution.map((entry, index) => (
                            <Cell key={`cell-${index}`} fill={COLORS[Object.keys(COLORS)[index % Object.keys(COLORS).length]]} />
                          ))}
                        </Pie>
                        <Tooltip />
                        <Legend />
                      </PieChart>
                    </ResponsiveContainer>
                  </Card.Body>
                </Card>
              </Col>
              <Col md={6} className="mb-4">
                <Card>
                  <Card.Body>
                    <Card.Title>Points Scored by Period</Card.Title>
                    <ResponsiveContainer width="100%" height={300}>
                      <AreaChart data={prepareVisualizationData.pointsByPeriod}>
                        <CartesianGrid strokeDasharray="3 3" />
                        <XAxis dataKey="name" />
                        <YAxis />
                        <Tooltip />
                        <Legend />
                        <Area type="monotone" dataKey="points" stroke={COLORS.tertiary} fill={COLORS.tertiary} />
                      </AreaChart>
                    </ResponsiveContainer>
                  </Card.Body>
                </Card>
              </Col>
            </Row>
            <Row>
              <Col md={6} className="mb-4">
                <Card>
                  <Card.Body>
                    <Card.Title>Points Scored by Time in Period</Card.Title>
                    <ResponsiveContainer width="100%" height={300}>
                      <BarChart data={prepareVisualizationData.pointsByTime}>
                        <CartesianGrid strokeDasharray="3 3" />
                        <XAxis dataKey="name" />
                        <YAxis />
                        <Tooltip />
                        <Legend />
                        <Bar dataKey="points" fill={COLORS.quaternary} />
                      </BarChart>
                    </ResponsiveContainer>
                  </Card.Body>
                </Card>
              </Col>
              <Col md={6} className="mb-4">
                <Card>
                  <Card.Body>
                    <Card.Title>Matches Lost After Having a Lead</Card.Title>
                    <div className="d-flex justify-content-center align-items-center" style={{ height: '300px' }}>
                      <div className="text-center">
                        <h2 className="display-4" style={{ color: COLORS.quinary }}>{prepareVisualizationData.matchesLostAfterLead}</h2>
                        <p>Matches</p>
                      </div>
                    </div>
                  </Card.Body>
                </Card>
              </Col>
            </Row>
            <Row>
              <Col md={12} className="mb-4">
                <Card>
                  <Card.Body>
                    <Card.Title>Team Performance Summary</Card.Title>
                    <Table striped bordered hover responsive>
                      <thead>
                        <tr>
                          <th>Metric</th>
                          <th>Value</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>Total Matches</td>
                          <td>{prepareVisualizationData.winLossData.reduce((sum, item) => sum + item.value, 0)}</td>
                        </tr>
                        <tr>
                          <td>Win Percentage</td>
                          <td>
                            {((prepareVisualizationData.winLossData[0].value / 
                              prepareVisualizationData.winLossData.reduce((sum, item) => sum + item.value, 0)) * 100).toFixed(2)}%
                          </td>
                        </tr>
                        <tr>
                          <td>Most Active Weight Class</td>
                          <td>
                            {prepareVisualizationData.weightClassDistribution.reduce((a, b) => a.value > b.value ? a : b).name}
                          </td>
                        </tr>
                        <tr>
                          <td>Highest Scoring Period</td>
                          <td>
                            {prepareVisualizationData.pointsByPeriod.reduce((a, b) => a.points > b.points ? a : b).name}
                          </td>
                        </tr>
                        <tr>
                          <td>Matches Lost After Lead</td>
                          <td>{prepareVisualizationData.matchesLostAfterLead}</td>
                        </tr>
                      </tbody>
                    </Table>
                  </Card.Body>
                </Card>
              </Col>
            </Row>
          </>
        )}
      </Container>
    </DashboardLayout>
  );
};

export default TeamDataVisual;