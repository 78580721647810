// src/utils/apiUtils.js
import { auth } from './firebase';

// Use the environment variable directly, no fallback needed
const apiUrl = process.env.NODE_ENV === 'production' 
  ? '' // Empty string for relative URL in production
  : process.env.REACT_APP_API_URL || 'http://localhost:5001';

/**
 * Makes an authenticated API call to the backend
 * @param {string} endpoint - The API endpoint to call
 * @param {string} method - The HTTP method to use (GET, POST, PUT, DELETE)
 * @param {object} body - The request body (for POST and PUT requests)
 * @returns {Promise} - The response from the API
 */
export const apiCall = async (endpoint, method = 'GET', body = null) => {
  try {
    const user = auth.currentUser;

    if (!user) {
      throw new Error('User not authenticated');
    }

    const token = await user.getIdToken();

    const headers = {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${token}`
    };

    const config = {
      method,
      headers,
      credentials: 'include',
    };

    if (body) {
      config.body = JSON.stringify(body);
    }

    const response = await fetch(`${apiUrl}${endpoint}`, config);

    if (!response.ok) {
      const errorData = await response.json();
      throw new Error(errorData.message || 'An error occurred');
    }

    return response.json();
  } catch (error) {
    console.error('API call failed:', error);
    throw error;
  }
};

/**
 * Fetches suggestions based on a query
 * @param {string} queryType - The type of query (e.g., 'wrestler', 'team', 'event')
 * @param {string} query - The search query
 * @returns {Promise} - An array of suggestions
 */
export const fetchSuggestions = async (queryType, query) => {
  if (query.length < 2) return [];

  try {
    const data = await apiCall(`/api/wrestlers?${queryType}=${query}`);
    return data.map(item => item.name);
  } catch (error) {
    console.error(`Error fetching ${queryType} suggestions:`, error);
    return [];
  }
};

/**
 * Searches for a specific item
 * @param {string} queryType - The type of query (e.g., 'wrestler', 'team', 'event')
 * @param {string} query - The search query
 * @returns {Promise} - The search results
 */
export const searchItem = async (queryType, query) => {
  try {
    const data = await apiCall(`/api/wrestlers?${queryType}=${query}`);
    return data;
  } catch (error) {
    console.error(`Error searching ${queryType}:`, error);
    throw error;
  }
};

/**
 * Submits full wrestler data
 * @param {object} wrestlerData - The full wrestler data to submit
 * @returns {Promise} - The response from the API
 */
export const submitWrestlerData = async (wrestlerData) => {
  try {
    const result = await apiCall('/api/fullWrestlers', 'POST', wrestlerData);
    return result;
  } catch (error) {
    console.error('Error submitting wrestler data:', error);
    throw error;
  }
};
