import React, { useState } from 'react';
import { Container, Row, Col, Nav, Navbar, NavDropdown } from 'react-bootstrap';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { signOut } from 'firebase/auth';
import { auth } from '../utils/firebase';
import { useAuthState } from 'react-firebase-hooks/auth';
import '../styles/DashboardLayout.css';

const DashboardLayout = ({ children }) => {
  const location = useLocation();
  const navigate = useNavigate();
  const [user] = useAuthState(auth);
  const [activeNav, setActiveNav] = useState('overview');

  const handleLogout = async () => {
    try {
      await signOut(auth);
      navigate('/login');
    } catch (error) {
      console.error('Error signing out: ', error);
    }
  };

  return (
    <div className="dashboard-container">
      <Navbar bg="dark" variant="dark" expand="lg" className="top-navbar">
        <Container fluid>
          <Navbar.Brand as={Link} to="/">K4Wrestling</Navbar.Brand>
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse id="basic-navbar-nav" className="justify-content-end">
            <Nav>
              <Nav.Link as={Link} to="/features">Features</Nav.Link>
              <Nav.Link as={Link} to="/pricing">Pricing</Nav.Link>
              <Nav.Link as={Link} to="/about">About</Nav.Link>
              {user ? (
                <NavDropdown title={user.displayName || "User"} id="basic-nav-dropdown">
                  <NavDropdown.Item as={Link} to="/profile">Profile</NavDropdown.Item>
                  <NavDropdown.Divider />
                  <NavDropdown.Item onClick={handleLogout}>Logout</NavDropdown.Item>
                </NavDropdown>
              ) : (
                <>
                  <Nav.Link as={Link} to="/login">Login</Nav.Link>
                  <Nav.Link as={Link} to="/signup" className="btn btn-orange">Sign Up</Nav.Link>
                </>
              )}
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
      <Container fluid>
        <Row>
          <Col md={3} lg={2} className="sidebar">
            <Nav className="flex-column">
              <Nav.Link 
                as={Link} 
                to="/" 
                active={activeNav === 'overview'}
                onClick={() => setActiveNav('overview')}
              >
                Overview
              </Nav.Link>
              <Nav.Link 
                as={Link} 
                to="/aicoach" 
                active={activeNav === 'aicoach'}
                onClick={() => setActiveNav('aicoach')}
              >
                AI Coach
              </Nav.Link>
              <Nav.Link 
                as={Link} 
                to="/scoreform" 
                active={activeNav === 'scoreform'}
                onClick={() => setActiveNav('scoreform')}
              >
                Score Form
              </Nav.Link>
              <Nav.Link 
                as={Link} 
                to="/data-visual" 
                active={activeNav === 'data-visual'}
                onClick={() => setActiveNav('data-visual')}
              >
                Data Visualization
              </Nav.Link>
              <Nav.Link 
                as={Link} 
                to="/contact" 
                active={activeNav === 'contact'}
                onClick={() => setActiveNav('contact')}
              >
                Contact
              </Nav.Link>
            </Nav>
          </Col>
          <Col md={9} lg={10} className="main-content">
            {children}
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default DashboardLayout;