import React from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import { useAuthState } from '../hooks/useAuthState';
import Home from './Home';
import Login from './Login';
import ScoreForm from './ScoreForm';
import AiCoach from './AiCoach';
import Contact from './Contact';
import Signup from './Signup';
import LandingPage from './LandingPage';
import DashboardLayout from './DashboardLayout';
import Profile from './Profile';
import WrestlerDataVisual from './WrestlerDataVisual';
import TeamDataVisual from './TeamDataVisual';
import EventDataVisual from './EventDataVisual';

function App() {
  const { user, loading } = useAuthState();

  if (loading) {
    return <div>Loading...</div>;
  }

  return (
    <Router>
      <Routes>
        <Route path="/" element={<Home />} /> {/* Public route */}
        <Route path="/login" element={!user ? <Login /> : <Navigate to="/" />} />
        <Route 
          path="/scoreform" 
          element={user ? <ScoreForm /> : <Navigate to="/login" />} 
        />
        <Route path="/signup" element={!user ? <Signup /> : <Navigate to="/" />} />
        <Route 
          path="/aicoach" 
          element={user ? <DashboardLayout><AiCoach /></DashboardLayout> : <Navigate to="/login" />} 
        />
        <Route 
          path="/contact" 
          element={user ? <DashboardLayout><Contact /></DashboardLayout> : <Navigate to="/login" />} 
        />
        <Route 
          path="/profile" 
          element={user ? <Profile /> : <Navigate to="/login" />} 
        />
        <Route 
          path="/data-visual" 
          element={user ? <Navigate to="/data-visual/wrestler" /> : <Navigate to="/login" />} 
        />
        <Route 
          path="/data-visual/wrestler" 
          element={user ? <WrestlerDataVisual /> : <Navigate to="/login" />} 
        />
        <Route 
          path="/data-visual/team" 
          element={user ? <TeamDataVisual /> : <Navigate to="/login" />} 
        />
        <Route 
          path="/data-visual/event" 
          element={user ? <EventDataVisual /> : <Navigate to="/login" />} 
        />
      </Routes>
    </Router>
  );
}

export default App;
