import React from 'react';
import { Card, Row, Col, ListGroup, Button } from 'react-bootstrap';
import DashboardLayout from './DashboardLayout';
import { useAuthState } from 'react-firebase-hooks/auth';
import { auth } from '../utils/firebase';
import '../styles/Profile.css';

function Profile() {
  const [user] = useAuthState(auth);

  return (
    <DashboardLayout>
      <Row>
        <Col md={4}>
          <Card className="profile-card mb-4">
            <Card.Body className="text-center">
              <img
                src={user?.photoURL || 'https://via.placeholder.com/150'}
                alt="Profile"
                className="rounded-circle profile-image mb-3"
              />
              <h3>{user?.displayName || 'Wrestler Name'}</h3>
              <p className="text-muted">{user?.email}</p>
              <Button variant="orange">Edit Profile</Button>
            </Card.Body>
          </Card>
        </Col>
        <Col md={8}>
          <Card className="profile-card mb-4">
            <Card.Body>
              <h4 className="mb-3">Wrestler Information</h4>
              <ListGroup variant="flush">
                <ListGroup.Item>Weight Class: 165 lbs</ListGroup.Item>
                <ListGroup.Item>Team: Mountaineers Wrestling Club</ListGroup.Item>
                <ListGroup.Item>Years of Experience: 5</ListGroup.Item>
                <ListGroup.Item>Favorite Technique: Double Leg Takedown</ListGroup.Item>
              </ListGroup>
            </Card.Body>
          </Card>
          <Card className="profile-card">
            <Card.Body>
              <h4 className="mb-3">Recent Achievements</h4>
              <ListGroup variant="flush">
                <ListGroup.Item>1st Place - State Championships 2023</ListGroup.Item>
                <ListGroup.Item>3rd Place - Regional Tournament 2023</ListGroup.Item>
                <ListGroup.Item>Most Improved Wrestler - Team Awards 2022</ListGroup.Item>
              </ListGroup>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </DashboardLayout>
  );
}

export default Profile;