import React from 'react';
import { Nav } from 'react-bootstrap';
import { Link, useLocation } from 'react-router-dom';

const DataVisualNavbar = () => {
  const location = useLocation();

  return (
    <Nav className="data-visual-navbar mb-4" variant="tabs">
      <Nav.Item>
        <Nav.Link as={Link} to="/data-visual/wrestler" active={location.pathname === '/data-visual/wrestler'}>
          Wrestler
        </Nav.Link>
      </Nav.Item>
      <Nav.Item>
        <Nav.Link as={Link} to="/data-visual/team" active={location.pathname === '/data-visual/team'}>
          Team
        </Nav.Link>
      </Nav.Item>
      <Nav.Item>
        <Nav.Link as={Link} to="/data-visual/event" active={location.pathname === '/data-visual/event'}>
          Event
        </Nav.Link>
      </Nav.Item>
    </Nav>
  );
};

export default DataVisualNavbar;